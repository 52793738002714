import React, { Fragment, useEffect, useRef, useState } from 'react';
import Section from './Section';
import styles from '../../styles/modules/common/banner-graphic.module.scss';
import Image from './Image';
import { clearRecentlySetCookies, getCityDisplayName, getRecentlySetCookie, getSelectedCityFromCookie, recentlySetCookie, setSelectedCityInCookie, getCityOrder } from '../../utils/cities';
import { isMobile } from 'react-device-detect';
import GoogleAutoComplete from '../user-interaction/GoogleAutoComplete';
import { getGeoPos } from '../../utils/location';
import CITIES_SLUG from '../../city_constants';
import { Hidden } from '@mui/material';
import GoogleSearch from './ui-components/input/UIDropdown/GoogleSearch';
import UIModal from './ui-components/surface/UIModal';
import { combineParamsString, getParamsObjectFromString, getStaticPath, getTruncatedString, getUtmString } from '../../utils';
import styles1 from '../../styles/modules/components/city-selector.module.scss';
import router from 'next/router';
import { breadCrumbClickSegment, locationChangeClicked, locationSelectionPopup } from '../../tracking/segment/planPage';
import { getPageURL } from '../../utils/tracking/index';
import { locationSelectorTap } from '../../tracking/segment/homePage';
import { setCityChangePopUpAction } from '../../redux/actions/genericActions';
import store from '../../redux'
import { isAmcInstaCart } from '../../utils/cart';
import { GetGeoLocation } from '../../api/home';

interface Props {
    device_name?: string,
    cta_button_title?: string,
    banner_img?: any,
    onCtaClick?: (e?: React.MouseEvent) => {},
    className?: string,
    isCommon?: boolean | false,
    journeyType?: string,
    categories?: any,
    planType?: any,
    breadcrumb?: any,
    slug_mapping?: any,
    cart?: any,
    setShowToast?: any
}

const CityandBreadCrumb = (props: Props) => {
    const [location, setLocation] = useState<string>(getCityDisplayName(props.slug_mapping.city) || getSelectedCityFromCookie() || "Mumbai")
    const [locationViewStep, setLocationViewStep] = useState<number>( getRecentlySetCookie()==='true' ? 1 : 0)
    const [geoError, setGeoErro] = useState<boolean>(false)
    const [citySelector] = useState<boolean>(props.slug_mapping["cities_covered"]!==null ? true : false)
    const [open, setOpen] = useState<boolean>(false)
    const [isMobile, setMobile] = useState<boolean>(true)
    useEffect(() => {
        window.innerWidth < 481 ?
            setMobile(true)
            :
            setMobile(false)
    });
    const myRef: any = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            if (locationViewStep)
                document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [locationViewStep]);

    useEffect(()=>{
        let cookie_city = getSelectedCityFromCookie()
        let slug_city = getCityDisplayName(props.slug_mapping.city)
        if(props.slug_mapping.city && cookie_city!==slug_city)
        {
            store.dispatch(setCityChangePopUpAction({ state:true , city_data: {"url":props.slug_mapping.slug,"name":slug_city}}))
        }
    },[])

    useEffect(()=>{
        let params:any = getParamsObjectFromString(window.location.href)['urlParams'];
        if(params['cityreset']) {
            setSelectedCityInCookie(""); setLocation(""); 
        }
    })

    useEffect(()=>{
        if(getSelectedCityFromCookie() && getRecentlySetCookie()==="true")
        {
            setLocation(getSelectedCityFromCookie())
            setLocationViewStep(1)
        }   
    },[getSelectedCityFromCookie()])


    const handleClickOutside = e => {
        if ((locationViewStep===1 || locationViewStep===2) && myRef && !myRef.current.contains(e.target)) {
            setLocationViewStep(0)
            setGeoErro(false)
        }
        if(getRecentlySetCookie()==='true')
        {
            clearRecentlySetCookies()
        }
    };

    const detectLocation = async () => {
        let {lat, long}:any = await getGeoPos();        
        GetGeoLocation({lat:lat, long}).then((res) => {
            if (res) {
                var adresss = res.data.data[0]["address_components"];
                for (let i = 0; i < adresss.length; i++) {
                    if (adresss[i].types.includes("administrative_area_level_3")) {
                        let city: string = adresss[i].long_name
                        city = CITIES_SLUG[city.toUpperCase()] ? CITIES_SLUG[city.toUpperCase()]["city"] : city.charAt(0) + city.slice(1).toLowerCase()
                        setSelectedCityInCookie(city,'auto')
                        setLocation(city)
                        setLocationViewStep(1)
                        setGeoErro(false)
                    }
                }
            }
        })
            .catch(err => {
                if (err) {
                    if (err.message) {
                        setGeoErro(true)
                    }
                }
            })
    }

    const openDropdown = () => {
        setOpen(true)
        setLocationViewStep(0);
    }
    const redirectIfCityCovered = (slug_mapping: string, param_city: any) => {
        let city_covered = slug_mapping['cities_covered'];
        for (let city of city_covered){
            if(city.name.toLowerCase()==param_city.toLowerCase()){
                let url:any = `${combineParamsString(city.url, getUtmString())}`
                router.push(url)
            }
        }
      }
    React.useEffect(()=>{
        let params:any = getParamsObjectFromString(window.location.href)['urlParams'];
        let cityName:string = params['city'] && getCityDisplayName(params['city']?.toUpperCase())!==params['city'].toUpperCase() ? getCityDisplayName(params['city'].toUpperCase()) : false
        if(cityName)
        {
            redirectIfCityCovered(props.slug_mapping,cityName)
            setSelectedCityInCookie(cityName)
            setLocation(cityName)
        }
    })

    const setCity = (cityName) => {
        cityName = getCityDisplayName(cityName)
        setSelectedCityInCookie(cityName)
      }

    const breadCrumbClick = (click_text) => {
        breadCrumbClickSegment({'breadcrumb':props.breadcrumb, 'click_text':click_text})
    }
    return (
        <Fragment>
            <Section className={`${styles.banner} ${styles.city_breadcrumb}`.trim()} id="cta-banner" hasPadding={false}>
                <div className={`app-section__container container`} style={{"position":"relative"}}>
                    <div className={styles.location_breadcrumb}>
                        <div className={styles.breadcrumb}>
                            {
                             props.breadcrumb && props.breadcrumb?.length!=0 && (props.breadcrumb?.length==3 ?
                                <>
                                    <a className={styles.city} onClick={()=>{breadCrumbClick(props.breadcrumb[0])}} href={`/`}>{props.breadcrumb[0]}</a> /&nbsp;
                                    <a className={styles.city} onClick={()=>{breadCrumbClick(props.breadcrumb[1]); setCity(props.breadcrumb[1])}} href={`/?city=${props.breadcrumb[1]}`}>{props.breadcrumb[1]}</a> /&nbsp;
                                    <span>{props.breadcrumb[2]} </span>
                                </>
                            :
                            <>
                                <a className={styles.city} href={`/`} onClick={()=>{breadCrumbClick(props.breadcrumb[0])}}>{props.breadcrumb[0]} </a> /&nbsp;
                                <span>{props.breadcrumb[1]}</span>
                            </>
                            )
                            }
                        </div>
                        {!isMobile && <div className={styles.location_selection} onClick={()=>{locationSelectorTap({'location':getSelectedCityFromCookie(),...getPageURL(router)}),location.length !== 0 ? citySelector ? setLocationViewStep(3) : setLocationViewStep(2) : citySelector ? setLocationViewStep(3) : setLocationViewStep(2) }}>
                            <div className={styles.location_img}>
                                <Image width="22" height="22" src={"/static/images/home/map-pin.webp"} alt="map pin"/>
                            </div>
                            {location.length !== 0 ?
                                <p className={styles._city} onClick={() => {citySelector ? setLocationViewStep(3) : setLocationViewStep(2) }} suppressHydrationWarning>
                                    {getTruncatedString(location, 10)}
                                </p>
                                :
                                <p className={styles._city} onClick={() => { citySelector ? setLocationViewStep(3) : setLocationViewStep(2)}}>
                                    Select a city
                                </p>}
                        </div>}
                        {isMobile && <div className={styles.location_selection} onClick={()=>{ locationSelectorTap({'location':getSelectedCityFromCookie(),...getPageURL(router)}),location.length !== 0 ? citySelector ? setLocationViewStep(3) : openDropdown() : citySelector ? setLocationViewStep(3) : openDropdown() }}>
                            <div className={styles.location_img}>
                                <Image width="22" height="22" src={"/static/images/home/map-pin.webp"} alt="map pin" />
                            </div>
                            {location.length !== 0 ?
                                <p className={styles._city} onClick={() => {citySelector ? setLocationViewStep(3) : openDropdown() }} suppressHydrationWarning>
                                    {getTruncatedString(location, 10)}
                                </p>
                                :
                                <p className={styles._city} onClick={() => { citySelector ? setLocationViewStep(3) : openDropdown() }}>
                                    Select a city
                                </p>}
                        </div>}
                        {!isMobile && locationViewStep === 2 && <>
                            <div className={styles.location} ref={myRef}>
                                <div className={styles.request}>
                                    <div className={styles.location_img}>
                                        <Image width="25" height="25" src={"/static/images/home/location_icon.png"} alt="location icon"/>
                                    </div>
                                    <p>Please provide your location for best experience</p>
                                </div>
                                <hr />
                                <div className={styles.detect_location}>
                                    <div className={styles.location_img}>
                                        <Image width="20" height="20" src={"/static/images/home/pgeo_icon.png"} alt="pgeo icon"/>
                                    </div>
                                    <p onClick={detectLocation}>Detect My Location</p>
                                </div>
                                {geoError && <div className={styles.error_block}>  <div className={styles.err_img}><Image width="20" height="20" src={"/static/images/home/alert_circle.png"} /></div> <p className={styles.geo_error} >We do not have permission to detect your location. Enter location manually</p></div>}
                                <p className={styles.or}>or</p>
                                <GoogleAutoComplete onChange={(val) => { setSelectedCityInCookie(val); setLocation(val); setLocationViewStep(1) }} />
                            </div>
                        </>
                        }
                        {locationViewStep === 1 && <>
                            <div className={styles.location_set} ref={myRef}>
                                <div className={styles.request}>
                                    <Hidden only={['xs']}>
                                        <div className={styles.location_img}>
                                            <Image width="25" height="25" src={"/static/images/home/location_icon.png"} alt="location icon" />
                                        </div>
                                    </Hidden>
                                    <Hidden smUp>
                                        <div className={styles.location_img}>
                                            <Image width="25" height="25" src={"/static/images/home/location-trans.png"} alt="location trans ison"/>
                                        </div>
                                    </Hidden>
                                    <p suppressHydrationWarning>Location set to {getTruncatedString(location, 10)}</p>
                                </div>
                                <Hidden only={['xs']}>
                                    <p className={styles.change_option} onClick={() => { locationChangeClicked({...getPageURL(router)}); citySelector ? setLocationViewStep(3) : setLocationViewStep(2) }}>Change</p>
                                </Hidden>
                                <Hidden smUp>
                                    <p className={styles.change_option} onClick={() => { locationChangeClicked({...getPageURL(router)}); citySelector ? setLocationViewStep(3) : openDropdown()}}>Change</p>
                                </Hidden>
                            </div>
                            
                        </>
                        }
                        
                    </div>
                    <Hidden smUp>
                        <GoogleSearch onClick = {(city)=>{setLocation(city); setLocationViewStep(1)}} options={[]} placeholder="" onChange={()=>{}} hideMobile={true} open={open} setOpen={setOpen} />       
                    </Hidden>
                    {locationViewStep===3 && <CitySelectorGeneric slug_mapping={props.slug_mapping} device_name={props.device_name} setLocation={setLocation} setLocationViewStep={setLocationViewStep} cart={props.cart}/>}
                </div>
            </Section>
        </Fragment>
    );
}

const CitySelectorGeneric = (props: any) => {
    let city_count = props.slug_mapping['cities_covered']?.length;
    let imgSize = isMobile ? 64 : 100;
    let count_class_name = `count-${city_count}`
    let city_selector = <></>;

    const closeModal = () => {
        props.setLocationViewStep(0)
        return false
      }
  
    const hideModal = () => {
        props.setLocationViewStep(1)
        return false
    }
    const setCity = (city) => {
      const cityName = getCityDisplayName(city.name)
      const cookie_city = getSelectedCityFromCookie()
      if (cityName != cookie_city && isAmcInstaCart(props.cart) && props.cart?.quantity){
        store.dispatch(setCityChangePopUpAction({state:true,city_data:city}))
        hideModal()
      }else{
        setSelectedCityInCookie(cityName,'popup')
        recentlySetCookie()
        props.setLocation(cityName)
        router.push(combineParamsString(city.url, getUtmString()))
        hideModal()
      }
    }
    React.useEffect(()=>{
        locationSelectionPopup({...getPageURL()}) // Segment Event
    },[])
    
    city_selector = <UIModal isOpen onOverlayClick={closeModal} onClick={closeModal} isPlainBG xlarge={true}>
        <div className={styles1.city_selector}>
          <div className={styles1.header}>
              <h3>{props.slug_mapping['device_name'] || props.device_name} Service & Repair</h3>
              <h4>Cities we cover</h4>
          </div>
          <div className={styles1.content}>
            <div className={styles1.title}>Select a city from below</div>
            <div className={`${styles1.city_list} ${styles1[count_class_name]}`}>
            {
              getCityOrder(
                props.slug_mapping['cities_covered']
              ).map((city, index) => {
                return <>
                    <a onClick={()=>{ setCity(city)}} title={city.name} className={styles1.city}>
                      <div className={styles1.icon}><Image src={getStaticPath(`/static/images/cities/${getCityDisplayName(city.name)}.png`)} width={imgSize} height={imgSize} alt={`${getCityDisplayName(city.name)} image`} /></div>
                      <div className={styles1.city_name}>{getCityDisplayName(city.name)}</div>
                    </a>                 
                  {index == 1 && <br className={styles1.split2}/>}
                  {index == 2 && <br className={styles1.split3}/>}
                </>
              })
            }
            </div>
          </div>
        </div>
      </UIModal>
    return city_selector;
  }

  
export default CityandBreadCrumb;
